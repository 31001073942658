<template>
  <div>
    <Header :title="host.organisation ? host.organisation.name : null" :lead="null" :header="host.theme ? `https://api.tixgo.nl/files/` + host.theme.header_image : null" />

    <template v-if="host.organisation && host.organisation.description">
      <b-container fluid class="mb-5 header-pull">
        <b-card>
          <b-row align-v="center">
            <b-col md="6" order="2" order-md="1">
              <h2>Over {{ host.organisation.name }}</h2>
              <div class="mb-3" v-html="host.organisation.description" />
              <b-button :to="`/host/` + this.$route.params.host + `/agenda`" size="sm" variant="primary">
                Bekijk agenda
                <font-awesome-icon icon="chevron-right" class="ml-1" />
              </b-button>

              <hr />
              
              <div class="mb-3">
                <h4>Social & Links</h4>
                <b-button v-if="host.organisation.website" v-b-tooltip.hover title="Website" :href="'https://' + host.organisation.website" target="_blank" rel="nofollow" variant="dark" class="mr-2 mb-2">
                    <font-awesome-icon icon="globe" />
                </b-button>
                <b-button v-if="host.organisation.links.social_linkedin" v-b-tooltip.hover title="Linkedin" :href="'https://www.linkedin.com/' + host.organisation.links.social_linkedin" target="_blank" rel="nofollow" variant="linkedin" class="mr-2 mb-2">
                    <font-awesome-icon icon="fa-brands fa-linkedin" />
                </b-button>
                <b-button v-if="host.organisation.links.social_facebook" v-b-tooltip.hover title="Facebook" :href="'https://www.facebook.com/' + host.organisation.links.social_facebook" target="_blank" rel="nofollow" variant="facebook" class="mr-2 mb-2">
                    <font-awesome-icon icon="fa-brands fa-facebook" />
                </b-button>
                <b-button v-if="host.organisation.links.social_instagram" v-b-tooltip.hover title="Instagram" :href="'https://www.instagram.com/' + host.organisation.links.social_instagram" target="_blank" rel="nofollow" variant="instagram" class="mr-2 mb-2">
                    <font-awesome-icon icon="fa-brands fa-instagram" />
                </b-button>
                <b-button v-if="host.organisation.links.social_twitter" v-b-tooltip.hover title="Twitter" :href="'https://www.twitter.com/' + host.organisation.links.social_twitter" target="_blank" rel="nofollow" variant="twitter" class="mr-2 mb-2">
                    <font-awesome-icon icon="fa-brands fa-twitter" />
                </b-button>
                <b-button v-if="host.organisation.links.social_tiktok" v-b-tooltip.hover title="TikTok" :href="'https://www.tiktok.com/' + host.organisation.links.social_tiktok" target="_blank" rel="nofollow" variant="tiktok" class="mr-2 mb-2">
                    <font-awesome-icon icon="fa-brands fa-tiktok" />
                </b-button>
              </div>

            </b-col>
            <b-col md="6" order="1" order-md="2">
              <div class="mb-3 text-center">
                <b-img class="host-logo" fluid thumbnail :src="host.theme ? `https://api.tixgo.nl/files/` + host.theme.header_logo : null" />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
    </template>

    <div class="upcoming pt-5 pb-5 mb-5">
      <b-container fluid>
          <h2 class="mb-4">Evenementen</h2>
          <UpcomingEvents />
          <div class="text-center">
              <b-button variant="outline-primary" to="/coming-soon">Meer tonen</b-button>
          </div>
      </b-container>
    </div>

    <div class="past mb-5">
      <b-container fluid>
          <h2 class="mb-4">Afgelopen Evenementen</h2>
          <PastEvents />
          <div class="text-center">
              <b-button variant="outline-primary" to="/coming-soon">Meer tonen</b-button>
          </div>
      </b-container>
    </div>
    
  </div>
</template>
    
  <script>
    import Header from '@/shared/components/Header'
    import UpcomingEvents from '@/components/UpcomingEvents'
    import PastEvents from '@/components/PastEvents'
  
    export default {
      metaInfo: {
        title: 'Host',
      },
      components: {
        Header,
        UpcomingEvents,
        PastEvents,
      },
      data() {
        return {
          host: {},
        }
      },
      computed: {
        getFullUrl() {
          return window.location.href;
        },
        calcTicketPrice() {
          return function(ticket) {
            return Number(ticket.price) + Number(this.event.customer_ticket_fee);
          }
        },
        calcTicketPriceWithAmount() {
          return function(ticket) {
            return (Number(ticket.price) + Number(this.event.customer_ticket_fee)) * Number(ticket.amount);
          }
        }
      },
      methods: {
        getEvent: function() {
          this.$axios.get('https://api.tixgo.nl/event/' + this.$route.params.event)
            .then( response => {
                this.event = response.data;
            }
          ).catch((error) => {
            if (error.response.status == '404') { 
              this.$router.push('/404');
            } else {
              this.$router.push('/500');
            }
          });
        },
        getHost: function() {
          this.$axios.get('https://api.tixgo.nl/host/' + this.$route.params.host)
            .then( response => {
                this.host = response.data;
            }
          ).catch((error) => {
            if (error.response.status == '404') { 
              this.$router.push('/404');
            } else {
              this.$router.push('/500');
            }
          });
        },
      },
      created() {
        this.getHost();
      }
    }
  </script>

<style scoped>
.upcoming{
    background-color:#eee;
}
.host-logo{
  width:500px;
}
</style>