<template>
    <div>
        <template v-if="loading">
            <b-row cols="1" cols-md="2" cols-lg="4">
                <b-col>
                    <SkeletonCard />
                </b-col>
                <b-col>
                    <SkeletonCard />
                </b-col>
                <b-col>
                    <SkeletonCard />
                </b-col>
                <b-col>
                    <SkeletonCard />
                </b-col>
            </b-row>
        </template>
        <template v-else>
            <b-row cols="1" cols-sm="2" cols-lg="4">
                <b-col v-for="(event, key) in listings" :key="key">
                    <b-card
                        img-top
                        no-body
                        tag="article"
                        class="mb-4"
                    >
                        <b-card-img-lazy height="200" :src="`https://api.tixgo.nl/files/` + event.header_image + '/950'" :alt="event.name" />
                        <b-card-body>
                            <h4 class="card-title">{{ event.name }}</h4>
                            <b-card-text>
                                <span v-if="event.description" class="text-muted" v-html="$options.filters.truncate(event.description, 100)"></span>
                            </b-card-text>
                            <b-button :to="`/event/` + event.slug + `-` + event.slug_hash" variant="primary" class="stretched-link">
                                Tickets
                            </b-button>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
    import SkeletonCard from '@/components/SkeletonCard'
  
    export default {
        components: {
            SkeletonCard
        },
        props: {
            city: String,
        },
        data() {
            return {
                loading: true,
                listings: [],
            }
        },
        computed: {},
        methods: {
            getListing() {
                this.$axios.get("https://api.tixgo.nl/destination?filter[type]=more&filter[city]=" + this.city + '&random=true&limit=4')
                    .then( response => {
                        this.listings = response.data;
                        this.loading = false;
                    }
                ).catch((reason) => {
                    reason;
                });
            },
        },
        created() {
            this.getListing();
        }
    }
</script>